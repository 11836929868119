<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <div>
        <el-form ref="from" :model="from" class="oauthFrom">
          <el-radio v-model="radio" label="1" @change="radioCheck($event)">用户</el-radio>
          <el-radio v-model="radio" label="2" @change="radioCheck($event)">角色</el-radio>
          <el-select v-model="from.region" v-if="radio === '1'" clearable  style="margin:0 10px;" @change="refresh">
            <el-option v-for="(item, index) in defaultUserList" :key="index" :label="getUserLabel(item)" :value="item.username"></el-option>
          </el-select>
          <el-select v-model="from.region" v-if="radio === '2'" clearable  style="margin:0 10px;" @change="refresh">
            <el-option v-for="(item, index) in defaulRoleList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
          <el-button size="medium" type="primary" @click="submit">提交</el-button>
          <el-button size="medium" plain @click="refresh">刷新</el-button>
        </el-form>
      </div>
      <el-table class="autotable" style="width:100%" border :data="resultList"
        row-key="id"
       :tree-props="{children:'children',hasChildren:'hasChildren'}"
      >
        <el-table-column label="菜单名称" prop="name"  align="center"  width="240">
          <template slot-scope="{row}">
            <span>{{row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="菜单编码" prop="code"  align="center"  width="240">
          <template slot-scope="{row}">
            <span>{{row.code}}</span>
          </template>
        </el-table-column>
        <el-table-column type="selection" align="center" width="80">
          <template slot-scope="{row}">
            <el-checkbox-group v-model="distribute" v-if="row.type==3">
              <el-checkbox :label="row.code">{{ }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column label="操作按钮权限"  align="center"  width="1075">
          <template slot-scope="{row}">
            <div v-if="row.permission!=null&&row.permission.length > 0">
              <el-checkbox @change="checkAllChilde($event, row, row.code)" v-model="checkAll[row.code]">全选</el-checkbox>
              <el-checkbox-group v-model="checkList[row.code]" @change="selectChildrenCheck($event, row, row.code)">
                <el-checkbox v-for="menu in row.permission" :key="menu.code" :label="menu.code">
                  {{menu.name +'['+menu.code+']'}} 
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, obj } from '@/assets/js/common.js'
import { oauthData, oauthRole, oauthUser, oauthSubmit, userRefresh, roleRefresh } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  data () {
    return {
      from: {
        region: ''
      },
      radio: '',
      resultList: [],
      defaulRoleList: [],
      defaultUserList: [],
      checkAll: [],
      checkList: [],
      indeterminate: [],
      distribute: []
    }
  },
  created () {
    oauthData().then(res=>{
      this.resultList = res.data.data
        this.resetPermission()
        //渲染完成后更新展开节点
        this.$nextTick(()=>{
        this.expandAll()
      })
    })
  },
  methods: {
    submit () {
      var params = new URLSearchParams()
      var roleCode=""
      var userCode=""
      if (this.radio === '1') {
        userCode=this.from.region
        if(userCode==''){
          this.$message.error('请选择授权用户');
          return false;
        }
      }else  if (this.radio === '2'){
        roleCode=this.from.region
         if(roleCode==''){
            this.$message.error('请选择授权角色');
            return false;
        }
      }else{
        this.$message.error('请先勾选用户或角色，选择授权用户或角色之后再提交');
        return false;
      }
      params.append('role', roleCode)
      params.append('user', userCode)
      var menuPermission = "";//所有分配权限的菜单
	    var menus = "";//存放选中的菜单数据
    
      for(let i=0;i<this.distribute.length;i++){
        //拼接菜单字符串
	      menus += this.distribute[i] + ",";
        menuPermission += this.distribute[i]  + "_";
        var perms= this.checkList[this.distribute[i]]
        if(perms!=null&&perms.length>0){
          for(let j=0;j<perms.length;j++){
            menuPermission += perms[j] + "*";
          }          
        }
        menuPermission += ",";
      }

      params.append('menus', menus)
      params.append('menuPermission', menuPermission)
      oauthSubmit(params).then(res => {
        if (res.data.code === 100) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.dataList()
          this.refresh() 
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        if (err !== null && err !== '' && err.responseText !== null) {
          this.$message.error('提交失败,请重试')
        }
      })
    },
    resetPermission(){
      if(this.resultList!=null){
        for (var i = 0; i < this.resultList.length; i++) {
            var children= this.resultList[i].children
            if(children!=null&&children.length>0){
              for(var j=0;j<children.length;j++){
                this.$set(this.checkList, children[j].code, [])
                this.$set(this.checkAll, children[j].code, [])
              }
            }
          
        }
      }
    },
    refresh () {
      this.resetPermission()
      this.distribute=[]
      var params = new URLSearchParams()
      var perList = []
      var menusList = []
      if (this.radio === '1' && this.from.region !== '') {
        params.append('user', this.from.region)
        userRefresh(params).then(res => {
          var ret= res.data.data
          if (ret.menus!=null&& ret.menus.length !== 0) {
            for(let i=0;i<ret.menus.length;i++){
              var menuCode = ret.menus[i].menuCode
              menusList.push(menuCode)
              perList = []
              if(ret.permissions!=null&&ret.permissions.length>0){
                ret.permissions.forEach(function (item) {
                  if(item.menuCode==menuCode){
                    perList.push(item.permissionCode)
                  }
                })
                this.checkList[menuCode] = perList
              }
              this.distribute = menusList
            }
          }
        })
      } else if (this.radio === '2' && this.from.region !== '') {
        params.append('role', this.from.region)
        roleRefresh(params).then(res => {
          var ret =res.data.data
          if (ret.menus!=null&& ret.menus.length !== 0) { 
            for(let i=0;i<ret.menus.length;i++){
              var menuCode = ret.menus[i].menuCode
              menusList.push(menuCode)
              perList = []
              if(ret.permissions!=null&&ret.permissions.length>0){
                ret.permissions.forEach(function (item) {
                  if(item.menuCode==menuCode){
                    perList.push(item.permissionCode)
                  }
                })
                this.checkList[menuCode] = perList
              }
              this.distribute = menusList
            }
          }
        })
      }
    },
    // 数据
    dataList () {
      oauthData().then(res => {
        this.resultList = res.data.data
      })
    },
    // 角色列表
    getUserRoleList () {
      oauthRole().then(res => {
        if (res.data.code === 100) {
          this.defaulRoleList = res.data.data
        }
      })
    },
    // 用户列表
    getUserList () {
      oauthUser().then(res => {
        if (res.data.code === 100) {
          this.defaultUserList = res.data.data
        }
      })
    },
    getUserLabel(item){
         let realName =item.realName
         if(realName&&realName.length>20){
            realName= realName.substring(0,20)+'...'
         }
         return  realName +' ( '+ item.username+' )'
    },
    // 单选
    radioCheck (event) {
      this.from.region = ''
      this.distribute = []
    },
    expandAll(){
      const els= this.$el.getElementsByClassName('el-table__expand-icon')
      if(els!=null){
        for(let i=0;i<els.length;i++){
          els[i].click()
        }
      }
    },
    // 全选
    checkAllChilde (event, row, code) {
      var list = []
      row.permission.forEach(function (item) {
        list.push(item.code)
      })
      this.checkList[code] = event ? list : []
    },
    selectChildrenCheck (event, row, code) {
      if (event.length === row.permission.length) {
        this.checkAll[code] = true
      } else {
        this.checkAll[code] = false
      }
      console.log( this.checkList[row.code])
      // this.indeterminate[code] = checkedCount > 0 && checkedCount < row.data.length
    }
  },
  updated(){
    //this.refresh()
  },
  mounted () {
    this.dataList()
    this.getUserRoleList()
    this.getUserList()
    // obj.areaSize()
  }
}
</script>
<style>
.oauthTitle{
  font-size:14px;
  color:#666;
  padding: 0 100px 0 10px;
}
.tableDetail .oauthFrom .el-button{
  font-size: 13px !important;
  height: 38px !important;
  line-height: 38px;
  letter-spacing: 2px !important;
  width: auto !important;
}
.autotable th .el-checkbox__inner{
  width: 0;
  height: 0;
  border: 0
}
.autotable .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  color: #000;
  content: "分配权限";
  font-size: 14px;
  font-weight: bold;
}
.el-checkbox-group{
  display: inline-block;
  margin-left: 10px;
}
</style>
